<template>
    <div class="socials">
        <a href="https://www.instagram.com/sluzyyy/?hl=fr"><img src="../../assets/instagram.png" alt="instagram"> </a>
        <a href="https://www.tiktok.com/@1sluzyyy?lang=fr"><img src="../../assets/tiktok.png" alt="tiktok"> </a>
        <a href="https://www.youtube.com/@sluzyyy/"><img src="../../assets/youtube.png" alt="youtube"> </a>
    </div>
</template>

<style scoped>
div.socials {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    width: 100%;
}

img {
    height: 2em;
}
</style>