<template>
    <div class="linkContainer">
        <MainLink v-for="link in links" :key="link.url" :link="link"/>
        <div class="scroll-down"></div>
    </div>

</template>

<script>
import MainLink from '../elements/MainLink.vue';

export default {
    name: 'MainLinks',
    props: {
        links: {
            type: Array,
            required: true
        }
    },
    components: {
        MainLink
    }
}
 

</script>

<style scoped>
div.linkContainer {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    width: 90vw;
    flex-wrap: wrap;
    border-radius: var(--border-radius);
    background-color: var(--color-primary);
    color: white;
    text-align: center;
    margin: 0 auto;
    width : clamp(300px, 100vw, 1200px);
}

.scroll-down {
  position: fixed;
  bottom: 20px;
  right: 50%;
  width: 24px;
  height: 24px;
  border: 4px solid #000;
  border-width: 4px 4px 0 0;
  transform: rotate(135deg);
  animation: arrowAnimation 2s infinite;
}

@keyframes arrowAnimation {
    0% {
        transform: rotate(135deg) translate(0, 0);
    }
    50% {
        transform: rotate(135deg) translate(-20px, 20px);
    }
    100% {
        transform: rotate(135deg) translate(0, 0);
    }
}

</style>