<template>
    <SecondHeader/>
    <div class="downloadWrapper">
        <h1  v-if="link">Thank you for your purchase</h1>
        <div v-if="link" class="line"></div>
        <div v-if="link" class="download">
            <div class="downloadButton">
                <a @click="download" >
                    <button>Download</button>
                </a>
            </div>
        </div>
        <div v-else class="error">
            <h2 >Sorry, you have not paid for this product</h2>
            <div class="line"></div>
        </div>
        <DrumKitShort :kit="kit" v-if="kit"/>
    </div>
    <MainFooter/>
</template>

<script>
import SecondHeader from '../parts/SecondHeader.vue';
import MainFooter from '../parts/MainFooter.vue';
import DrumKitShort from '../elements/DrumKitShort.vue';
import axios from 'axios';


export default {
    name: 'MainPage',
    data() {
        return {
            link: '',
            kit : null
        }
    },
    components: {
        SecondHeader,
        MainFooter,
        DrumKitShort
    },
    methods: {
        download() {
            // download from link
            window.open(this.link, '_blank');
            // redirect to home
            this.$router.push('/')
            
        }
    },
    async mounted() {
        // if paid
        const paid = await axios.get(process.env.VUE_APP_API_HOST + '/drumkit/paiement_status/' +this.$route.params.secreKey)

        if (paid.data == true) return this.link = process.env.VUE_APP_API_HOST + '/drumkit/success/' +this.$route.params.secreKey +"/"+ this.$route.params.id;

        var response = await axios.get(process.env.VUE_APP_API_HOST + '/drumkit' )

        this.kit = response.data.find(kit => kit.id == this.$route.params.id)
        
    }
}

</script>

<style scoped>
.downloadWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin-top: 5rem;
}

h1 {
    color: var(--primary-color);
    text-align: center;
    margin-top: 5rem;
}

div.line {
    width: 30%;
    height: 0.2rem;
    background-color: var(--primary-color);
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
}

.download {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

.downloadButton {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.downloadButton button {
    width: 20rem;
    height: 5rem;
    border-radius: 1rem;
    background-color: var(--primary-color);
    color: var(--secondary-color);
    font-size: 1.5rem;
    font-weight: bold;
    border: none;
    transition: all 0.3s ease-in-out;
}

.downloadButton button:hover {
    transform: scale(1.05);
}

@media screen and (max-width: 768px) {
    .downloadWrapper {
        flex-direction: column;
    }
}

</style>