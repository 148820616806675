<template>
    <div class="countdown">
        <p class="till">Ends in :</p>
        <div class="timer">{{days}} d</div>
        <div class="timer">{{ hours }} h</div>
        <div class="timer">{{ minutes }} m</div>
        <div class="timer">{{ seconds }} s</div>
  </div>
</template>

<script>

export default {
    name: 'MainCountdown',
    //end date
    props: {
        end: {
            type: String,
            required: true,
        }
    },
    mounted() {
        this.countdown();
    },
    data() {
        return {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
        }
    },
    methods: {
        countdown() {
            const endDate = new Date(this.end).getTime();
            const now = new Date().getTime();
            const distance = endDate - now;

            const days = Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours = Math.floor(distance % (1000 * 60 * 60 * 24) / (1000 * 60 * 60));
            const minutes = Math.floor(distance % (1000 * 60 * 60) / (1000 * 60));
            const seconds = Math.floor(distance % (1000 * 60) / 1000);

            this.days = days;
            this.hours = hours;
            this.minutes = minutes;
            this.seconds = seconds;

            setTimeout(this.countdown, 1000);
        }
    }
}

</script>

<style scoped>

    p.till {
        font-size: 1rem;
        font-weight: 900;
        margin: 0 10px;
        color: var(--primary-color);
        padding: 10px;
        border-radius: 5px;
    }
    .countdown {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .timer {
        font-size: 1rem;
        font-weight: 900;
        margin: 0 10px;
        color: var(--primary-color);
        padding: 10px;
        border-radius: 5px;
    }

    @media screen and (max-width: 768px) {

        p.till {
            font-size: 0.7rem;
        }   
        .timer {
            font-size: 0.7rem;
        }
    }
</style>