<template>
    <div class="headerwrapper">
        <MainMenu/>
    </div>
</template>

<script>
import MainMenu from '../elements/MainMenu.vue';

export default {
    name: 'SecondHeader',
    components: {
        MainMenu
    }
}
</script>

<style scoped>

div.headerwrapper {
    position: relative;
    height: var(--header-height);
    width: 100%;
    background-color: black;
}

</style>