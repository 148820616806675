<template>
    <form @submit.prevent="submitForm" class="register" id="register-form">
            <h1 class="register__title">Register</h1>
            <label class="error"></label>
            <input v-model="form.email" type="email" class="register__input" placeholder="Email" id="email" autocomplete="email"/>
            <input v-model="form.password" type="password" class="register__input" placeholder="Password" id="password"/>
            <input v-model="confirmPassword" type="password" class="register__input" placeholder="Confirm Password" id="confirmPassword"/>
            <button  class="register__button">Sign Up</button>
            <p class="register__login">Already have an account? &nbsp;<a @click="$emit('login')">Login</a></p>
    </form>
</template>

<script>
import axios from 'axios';


export default {
    name: 'MainRegister',
    data() {

        return {
            form: {
                email: 'asada@rex.com',
                password: 'testtest1',
                
            },
            confirmPassword: 'testtest1'
        }
    },
    methods: {
        submitForm() {
            if (this.form.password !== this.confirmPassword) {
                document.querySelector('label.error').innerHTML = 'Passwords do not match';
                return;
            }

            // send form
            axios.post('http://localhost:3000/auth/register', this.form)
            .then((res) => {
                console.log(res);
                if (res.data.error) {
                    document.querySelector('label.error').innerHTML = res.data.error;
                } else {
                    this.$emit('login');
                }
            }).catch((err) => {
                document.querySelector('label.error').innerHTML = err.response.data.error;
            });
        }
    }
}

</script>

<style scoped>
/* modern  */
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700");

* {
    box-sizing: border-box;
}

h1 {
    font-weight: bold;
    margin: 0;
}

h2 {
    text-align: center;
}

p {
    font-size: 14px;
    font-weight: 100;
    line-height: 20px;
    letter-spacing: 0.5px;
    margin: 20px 0 30px;
}

span {
    font-size: 12px;
}


a {
    color: #333;
    font-size: 14px;
    text-decoration: none;
    margin: 15px 0;
    cursor: pointer;
}

button {
    border-radius: 20px;
    border: 1px solid #30c174;
    background-color: #30c174;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: bold;
    padding: 12px 45px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 80ms ease-in;
}

input {
    background-color: #eee;
    border: none;
    padding: 12px 15px;
    margin: 8px 0;
    width: 100%;
}

input:focus {
    outline: none;
    border: 1px solid #30c174;
}

button:active {
    transform: scale(0.95);
}

button:focus {
    outline: none;
}

button.ghost {
    background-color: transparent;
    border-color: #FFFFFF;
}

form {
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 50px;
    height: 60%;
    text-align: center;
    border-radius: 10px;
}

input {
    background-color: #eee;
    border: none;
    padding: 12px 15px;
    margin: 8px 0;
    width: 100%;
}


.container {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 
            0 10px 10px rgba(0,0,0,0.22);
    position: relative;
    overflow: hidden;
    width: 768px;
    max-width: 100%;
    min-height: 480px;
}


.form-container {
    position: absolute;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;
}


.sign-in-container {
    left: 0;
    width: 50%;
    z-index: 2;
}

label.error {
    color: red;
    font-size: 12px;
    margin: 0;
    padding: 0;
}   



</style>