<template>
    <iframe style="border-radius:12px" src="https://open.spotify.com/embed/playlist/1q3Jprm1CpwCMkd4wr4G8b?utm_source=generator&theme=0" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
</template>
  
<script>
  
  export default {
   name: 'MainPlaylist',
  };
</script>


<style scoped>
iframe {
  border: 0;
  border-radius: 12px;

  width: clamp(300px, 60%, 1200px);

  min-height: 60vh;
}
</style>