<template>
    <form @submit.prevent="submitForm" class="login__form">
        <h1 class="login__title">Login</h1>
        <label class="error"></label>
        <input v-model="form.email" type="text" class="login__input" placeholder="email" id="email" autocomplete="email"/>
        <input v-model="form.password" type="password" class="login__input" placeholder="Password" id="password" autocomplete="current-password"/>
        <a href="#" class="login__forgot">Forgot Password?</a>
        <button class="login__button">Sign In</button>
        <p class="login__signup">Don't have an account? &nbsp;<a @click="$emit('register')">Sign up</a></p>
    </form>
</template>

<script>
import axios from 'axios';

export default {
    name: 'MainLogin',
    data() {
        return {
            form: {
                email: 'asada@rex.com',
                password: 'testtest1'
            }
        }
    },
    methods: {
        submitForm() {
            // send form
            axios.post('http://localhost:3000/auth/login', this.form)
            .then((res) => {
                console.log(res);
                if (res.data.error) {
                    document.querySelector('label.error').innerHTML = res.data.error;
                } else {
                    // save token
                    localStorage.setItem('token', res.data.token);
                    
                    // token to default header
                    axios.defaults.headers.common['authorization'] = res.data.token;

                    // get user info
                    axios.get('http://localhost:3000/user').then((res) => {
                        console.log(res);
                        this.$emit('logged', res.data.user);
                    }).catch((err) => {
                        console.log(err);
                    });
                }
            }).catch((err) => {
                document.querySelector('label.error').innerHTML = err.response.data.error;
            });
        }
    }
}
</script>

<style scoped>

label.error {
    color: red;
    font-size: 12px;
    margin-bottom: 10px;
    display: block;
}

/* modern style */
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700");

* {
    box-sizing: border-box;
}

h1 {
    font-weight: bold;
    margin: 0;
}

h2 {
    text-align: center;
}

p {
    font-size: 14px;
    font-weight: 100;
    line-height: 20px;
    letter-spacing: 0.5px;
    margin: 20px 0 30px;
}

span {
    font-size: 12px;
}

a {
    color: #333;
    font-size: 14px;
    text-decoration: none;
    margin: 15px 0;
    cursor: pointer;
}

button {
    border-radius: 20px;
    border: 1px solid #30c174;
    background-color: #30c174;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: bold;
    padding: 12px 45px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 80ms ease-in;
}

button:active {
    transform: scale(0.95);
}

button:focus {
    outline: none;
}

button.ghost {
    background-color: transparent;
    border-color: #FFFFFF;
}

form {
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 50px;
    height: 60%;
    text-align: center;

    border-radius: 10px;
}

input {
    background-color: #eee;
    border: none;
    padding: 12px 15px;
    margin: 8px 0;
    width: 100%;
}


.container {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 
                0 10px 10px rgba(0,0,0,0.22);
    position: relative;
    overflow: hidden;
    width: 768px;
    max-width: 100%;
    min-height: 480px;
}


.form-container {
    position: absolute;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;
}


.sign-in-container {
    left: 0;
    width: 50%;
    z-index: 2;
}


.container.right-panel-active .sign-in-container {
    transform: translateX(100%);
}


.sign-up-container {
    left: 0;
    width: 50%;
    opacity: 0;
    z-index: 1;
}


.container.right-panel-active .sign-up-container {
    transform: translateX(100%);
    opacity: 1;
    z-index: 5;
    animation: show 0.6s;
}


@keyframes show {
    0%, 49.99% {
        opacity: 0;
        z-index: 1;
    }
    
    50%, 100% {
        opacity: 1;
        z-index: 5;
    }
}


.overlay-container {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;
    transition: transform 0.6s ease-in-out;
    z-index: 100;
}


.container.right-panel-active .overlay-container{
    transform: translateX(-100%);
}

</style>