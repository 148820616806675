<template>
    <MainHeader/>
    <DrumKits/>
    <MainFooter/>
</template>

<script>
import MainHeader from '../parts/MainHeader.vue';
import DrumKits from '../parts/DrumKits.vue';
import MainFooter from '../parts/MainFooter.vue';



export default {
    name: 'MainPage',
    components: {
        DrumKits,
        MainHeader,
        MainFooter
    },
    data () {
        return {
            kit: {
                name: '',
                price: '',
                description: '',
                content: '',
                promo_price: '',
                id: -1
            }
        }
    },
}

</script>