// router.js
import { createRouter, createWebHistory } from 'vue-router';
import MainPage from '@/components/views/MainPage.vue';
import MainDiscography from '@/components/views/MainDiscography.vue';
import MainContact from '@/components/views/MainContact.vue';
import MainError from '@/components/views/MainError.vue';
import MainAbout from '@/components/views/MainAbout.vue';
import MainDrumkitSingle from '@/components/views/MainDrumkitSingle.vue';
import MainDownload from '@/components/views/MainDownload.vue';


const routes = [
    { path: '/', component: MainPage },
    { path: '/discography', component: MainDiscography },
    { path: '/contact', component: MainContact },
    { path: '/:pathMatch(.*)*', component: MainError },
    { path: '/about', component: MainAbout },
    { path: '/drumkit/:id', component: MainDrumkitSingle },
    { path: '/drumkit/download/:secreKey/:id', component: MainDownload },


];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;