<template>
    <div class="main-link">
        <a :href="link.url" target="_blank">
            <p> <span class="siteName">{{link.site_name}}</span> - <span class="title">{{link.title}}</span>  </p>
            <img :src="link.image" :alt="link.title" />
        </a>
    </div>

</template>

<script>

export default {
    name: 'MainLink',
    props: {
        link: {
            type: Object,
            required: true
        }
    }
}

</script>

<style scoped>

div.main-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--padding);
    padding: var(--padding);
    border-radius: var(--border-radius);
    /* background-color: #0000003b; */
    text-align: center;
    transition: all 0.3s ease-in-out;
    /* 3 image shrink to 33% */
    flex: 0 0 1;
    min-width: 300px;
}

div.main-link a {
    text-decoration: none;
    color: var(--primary-color);
}

div.main-link:hover {
    transform: scale(1.05);
}

div.main-link img {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

div.main-link p {
    font-size: 1em;
    font-weight: bold;
    text-align: left;
}

div.main-link p span.siteName {
    color: #434040;
    font-size: 0.8em;
}

div.main-link p span.title {
    font-size: 1.2em;
}



</style>