<template>
    <SecondHeader/>
    <DrumKit v-if="kit.id !== -1" :kit="kit" />
    <MainLoading v-else-if="loading"/>
    <p v-else>Kit not found</p>
    <MainFooter/>
</template>

<script>
import SecondHeader from '../parts/SecondHeader.vue';
import DrumKit from '../parts/DrumKit.vue';
import MainFooter from '../parts/MainFooter.vue';
import MainLoading from '../elements/MainLoading.vue';

import axios from 'axios'


export default {
    name: 'MainPage',
    components: {
        DrumKit,
        SecondHeader,
        MainFooter,
        MainLoading
    },
    data () {
        return {
            kit: {
                name: '',
                price: '',
                description: '',
                content: '',
                promo_price: '',
                id: -1
            },
            loading : true
        }
    },
    methods : {
        async retriveData (){
            var response = await axios.get(process.env.VUE_APP_API_HOST+'/drumkit').catch(error => {
                console.log(error)
            })
            if (response == undefined) {
                this.loading = false
                return
            }

            var data = response.data
            
            // get element with id == this.kit.id
            this.kit = data.find(element => element.id == this.$route.params.id) || this.kit
            this.loading = false

        }
    },
    beforeMount() {
        this.retriveData()
    }
}
</script>

<style scoped>
p {
    color: var(--primary-color);
    font-size: 2rem;
    text-align: center;
    margin-top: 5rem;
}
</style>