<template>
    <SecondHeader/>
    <div class="about">
        <h1>About me</h1>
        <MainLoading v-if="loading"/>
        <MainLinks v-else :links="links"/>
    </div>
    <MainFooter/>
</template>

<script>
import SecondHeader from '../parts/SecondHeader.vue';
import MainFooter from '../parts/MainFooter.vue';
import MainLinks from '../parts/MainLinks.vue';
import MainLoading from '../elements/MainLoading.vue';

export default {
    name: 'MainAbout',
    methods: {
        sendMail() {
            console.log('send mail');
        }
    },
    components: { 
        SecondHeader,
        MainFooter,
        MainLinks,
        MainLoading
    },
    data() {
        return {
            links:[],
            loading: true,
            articles: [
                { 
                    url :"https://amp.20min.ch/story/produzent-sluzyyy-machte-party-mit-migos-898187483963", 
                    title: "Produzent Sluzyyy machte Party mit Migos", 
                    site_name:"20 Minuten",
                    image: require("../../assets/articles/Produzent Sluzyyy machte Party mit Migos.jpg")
                },
                { 
                    url :"https://www.redbull.com/ch-fr/beatmaking-sluzyyy",
                    title: "Beatmaking  Comment Sluzyyy a créé « To the Bone » de Quavo & Takeoff",
                    site_name:"Red Bull",
                    image: require("../../assets/articles/Beatmaking  Comment Sluzyyy a créé « To the Bone » de Quavo & Takeoff.jpg")
                },
                {
                    url :"https://blog.artistconnect.de/sluzyyy/",
                    title:"Sluzyyy",
                    site_name:"ArtistConnect",
                    image: require("../../assets/articles/Sluzyyy.png") 
                },
                { 
                    url :"https://www.redbull.com/ch-fr/meilleures-collaborations-suisses-international-pt3", 
                    title: "Les meilleures collaborations des artistes suisses à l’international (3)",
                    site_name:"Red Bull",
                    image: require("../../assets/articles/Les meilleures collaborations des artistes suisses à l’international (3).jpg")
                },
            ],
     
        }
    },
    async mounted() {
        for (const article of this.articles) {
            if (article.title != null) {
                this.links.push(article) 
                continue;
            }
        }

        this.loading = false;
    }
}
</script>