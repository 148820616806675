<template>
    <div class="kitWrapper">
        <div class="left">
            <img :src="image" alt="" class="kitImage">
            <button @click="submit(kit.id)" class="buy">Buy</button>
        </div>
        <div class="kitInfos">
            <h1 class="name">{{kit.name}}</h1>
            <div class="price">
                <div v-if="promo" class="pricethis.kit">
                    <span class="discount">{{kit.price}} $</span> <MainCountdown :end="kit.end_of_promotion_date"/>
                </div>
                <h2 v-if="promo" class="price">  {{promo_price}} $</h2>
                <h2 v-else class="price">  {{kit.price}} $</h2>

            </div>
            <div class="description">
                <p>{{kit.description}}</p>
                <ul class="twoColonne">
                    <li v-for="(item, index) in to_list(content_names, kit.content)" :key="index">{{item}}</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios'
import MainCountdown from '../elements/MainCountdown.vue';

export default {
    name: 'DrumKit',
    methods: {
        async submit (id) {
            // add the processing class to the button
            document.querySelector('button.buy').classList.add('loading')

            var response = await axios.get( process.env.VUE_APP_API_HOST+'/drumkit/proceed/'+id)
            var data = response.data

            window.location.href = data
        },

        to_list(text1, text2) {
            var list1 = text1.split(';');
            var list2 = text2.split(';');

            var list = [];
            for (var i = 0; i < list1.length; i++) {
                list.push(list1[i] + ' ' + list2[i]);
            }
            return list;
        },
    },
    components: {
        MainCountdown
    },
    data () {
        return {
            content_names: "808s; Bass; Claps; Hihats; Kicks; Open Hats; Percs; Rims; Snares",
            promo: false,
            image: null,
            promo_price: 0,
        }
    },
    props: {
        kit: {
            type: Object,
            required: true
        }
    },
    mounted() {
        var today = new Date();
        var end = new Date(this.kit.end_of_promotion_date);

        if (today < end) this.promo = true;
            
        this.promo_price = (this.kit.price - (this.kit.price * this.kit.promo_percent)).toFixed(2)
        this.image = require('../../assets/drumkits/'+this.kit.name+'.jpg')
    }
}
</script>

<style>


button.buy.loading{
    animation: loading 1s 0.3s infinite;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

@keyframes loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    
}

h2.price{
    color: var(--primary-color);
}

div.pricethis.kit{
    display: flex;
    justify-content: left;
    align-items: center;

}

span.discount {
    color: var(--primary-color);;
    text-decoration: line-through;
    font-size: 1.2em;
    padding: 0;
    margin: 0;   
}

ul.twoColonne {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;

    width: 100%;
    height: 100%;
    padding: 1em;
}

li {
    font-size: 0.8em;

    margin: 0;
    padding: 0;

    color: var(--primary-color);

    text-align: left;

    text-transform: uppercase;

    font-weight: 500;
}

div.left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    gap : 20px;

    width: 50%;
    height: 100%;
}


div.kitWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    width: clamp(260px, 90%, 1100px);
    height: 100%;

    min-height: 60vh;

    background-color: var(--secondary-color);

    margin: 0 auto;
    padding: clamp(20px, 5%, 50px);
}

img.kitImage {
    width: 100%;

}

div.kitInfos {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;

    width: 50%;
    height: 100%;
}

button.buy {
    width: 100%;
    height: 50px;

    background-color: var(--accent-color);
    color: var(--secondary-color);
    border: none;
    border-radius: 5px;

    transition: width 0.3s ease-in-out, border-radius  0.2s 0.1s ease-in-out;
}

button.buy:hover {
    transform: scale(0.99);
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    div.kitWrapper {
        flex-direction: column;
    }
    
    img.kitImage {
        width: 100%;
        height: 50%;
    }

    div.kitInfos {
        width: 100%;
        height: 50%;

        text-align: center;
    }

    div.left {
        width: 100%;
    }
    div.pricethis.kit{
        flex-direction: column;
    }
}


</style>