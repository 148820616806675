<template>
    <div class="video notLoaded">
        <video autoplay muted playsinline loop>
            <source src="../../assets/video.mp4" type="video/mp4">
        </video>
    </div>
</template>

<script>
export default {
    name: 'MainVideo',
    data() {
        return {
            video: null,
        }
    },
    mounted() {
        this.video = document.querySelector('div.video video');
        this.video.addEventListener('loadeddata', () => {
            this.video.parentElement.classList.remove('notLoaded');
        });
    }
}
</script>

<style scoped>
div.video {
    position: absolute;
    top: 0;
    left: 0;
    height: var(--video-height);
    width: var(--video-width);
    background-color: var(--primary-color);
    z-index: -1;
}

div.video video {
    position: absolute;
    top: 0;
    left: 0;
    height: var(--video-height);
    width: var(--video-width);
    object-fit: cover;
}

div.video video.logo360{
   /* on top of the video and centered */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    /* same size as the video */
    width: var(--video-width);
    height: auto;

    /* center the image */
    object-fit: fill;;

    /* on top of the video */
    z-index: 100;
}

div.video h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    color: var(--secondary-color);
    font-size: 4em;
    font-weight: 900;
    text-align: center;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    z-index: 100;
}

div.video img.slime {
    position: absolute;
    top: 25%;
    left: 15%;

    width: 200px;
    object-fit: cover;
    z-index: 50;
}

div.video::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: var(--video-overlay-height);
    width: var(--video-overlay-width);
    z-index: 49;
}

div.video.notLoaded::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    font-size: 5em;
    font-weight: 900;
    z-index: 49;

    /*white line loading*/
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);
    background-size: 600% 600%;

    animation: loadingWhiteWaves 2s ease-in-out infinite;

    
}

@keyframes loadingWhiteWaves {
    0% {
        background-position: 0% 0%;
    }
    50% {
        background-position: 100% 0%;
    }
    100% {
        background-position: 0% 0%;
    }
}

/*phone */
@media screen and (max-width: 768px) {
    div.video h1 {
        font-size: 2em;
        width: 90%;
    }

    div.video img.slime {
        width: 100px;
    }
}

</style>