<template>
    <div class="headerwrapper">
        <MainMenu/>
        <MainVideo/>
    </div>
</template>

<script>
import MainMenu from '../elements/MainMenu.vue';
import MainVideo from '../elements/MainVideo.vue';

export default {
    name: 'MainHeader',
    components: {
        MainVideo,
        MainMenu
    }
}

</script>

<style scoped>
div.headerwrapper {
    position: relative;
    height: 100vh;
    width: 100%;
}
</style>