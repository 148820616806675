<template>
    <SecondHeader/>
    <div class="about">
        <h1>Discography</h1>
        <MainPlaylist/>
    </div>
    <MainFooter/>
</template>

<script>
import SecondHeader from '../parts/SecondHeader.vue';
import MainFooter from '../parts/MainFooter.vue';
import MainPlaylist from '../elements/MainPlaylist.vue';

export default {
    name: 'MainAbout',
    methods: {
        sendMail() {
            console.log('send mail');
        }
    },
    components: { 
        SecondHeader,
        MainFooter,
        MainPlaylist
    }
}
</script>