<template>
    <div class="popWrapper">
        <div @click="$emit('close')" class="quit">×</div>
        <slot/>
    </div>
</template>

<script>

export default { 
    name: 'MainPopup',
    mounted() {
        // remove and block scroll
        document.body.style.overflow = 'hidden';
        document.body.style.height = '100vh';
    },
    unmounted() {
        // remove and block scroll
        document.body.style.overflow = 'auto';
        document.body.style.height = 'auto';
    },
}

</script>

<style scoped>
div.popWrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

div.quit {
    font-size: 2em;
    padding: 0.5em;
    cursor: pointer;
    color: white;
}
</style>