<template>
    <SecondHeader/>
    <div class="contact">
        <h1>Contact</h1>
        <form ref="form" @submit.prevent="sendEmail">
            <label>Name</label>
            <input type="text" name="user_name">
            <label>Email</label>
            <input type="email" name="user_email">
            <label>Message</label>
            <textarea name="message"></textarea>
            <input type="submit" value="Send">
        </form>
    </div>
    <MainFooter/>
</template>


<script>
import SecondHeader from '../parts/SecondHeader.vue';
import MainFooter from '../parts/MainFooter.vue';

export default {
    name: 'MainContact',
    methods: {
        sendEmail() {
            console.log('send mail');   
        }
    },
    components: { 
        SecondHeader,
        MainFooter 
    }
}

</script>

<style>
.contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
}

.contact form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
}

.contact form input, .contact form textarea {
    width: 100%;
    margin-bottom: 2%;
    padding: 2%;
    border: 1px solid #000;
    border-radius: 5px;
}

.contact form input[type="submit"] {
    width: 50%;
    margin: 0 auto;
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 5px;
}

.contact form input[type="submit"]:hover {
    background-color: #fff;
    color: #000;
    border: 1px solid #000;
}

.contact form input[type="submit"]:active {
    background-color: #000;
    color: #fff;
    border: none;
}


</style>