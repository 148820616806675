<template>
    <a :href="link" class="kit">
        <div class="kitWrapper">
            <img :src="image" alt="kit.name">
            <div class="infos">
                <h2>{{kit.name}}</h2>
                <div class="price">
                    <p v-if="promo" class="discount">{{kit.price}}$</p>
                    <p v-if="promo">{{promo_price}}$</p>
                    <p v-else>{{kit.price}}$</p>
                </div>
            </div>
        </div>
    </a>
</template>

<script>
export default {
    name: 'DrumKitShort',
    data () {
        return {
            promo: false,
            image: null,
            promo_price: 0,
            link: '/drumkit/'+ this.kit.id
        }
    },
    props: {
        kit: Object
    },
    mounted() {
        var today = new Date();
        var end = new Date(this.kit.end_of_promotion_date);

        if (today < end) this.promo = true;

        this.promo_price = (this.kit.price - (this.kit.price * this.kit.promo_percent)).toFixed(2)
        this.image = require('../../assets/drumkits/'+this.kit.name+'.jpg')
    }
}
</script>

<style scoped>
.kit {
    text-decoration: none;
    color: var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: center;

}

div.kitWrapper {
    display: block;
    padding: 0;
    border-radius: 1rem;
    background-color: var(--secondary-color);
    transition: all 0.3s ease-in-out;
    position: relative;
    width: 20em;
    height: min-content;
}

.kitWrapper:hover {
    transform: scale(1.05);
}

.kitWrapper img {
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    display: block;
}

/* infos over the image */
div.infos {
display: block;
}

div.price{
    font-weight: bolder;
} 

p.discount {
    color: var(--primary-color);;
    text-decoration: line-through;
    font-size: 1.2em;
    padding: 0;
    margin: 0;   
}

</style>