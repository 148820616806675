<template>
<div id="drumkit" class="kitsWrapper">
    <h1>Drum Kits</h1>
    <div class="line"></div>
    <div class="kits">
        <DrumKitShort v-for="kit in kits" :key="kit.id" :kit="kit" />
    </div>
</div>
</template>

<script>
import axios from 'axios'
import DrumKitShort from '../elements/DrumKitShort.vue'

export default {
    name: 'DrumKits',
    data () {
        return {
            kits: []
        }
    },
    methods : {
        async retriveData (){
            var response = await axios.get(process.env.VUE_APP_API_HOST+'/drumkit')
            var data = response.data

            data.sort((a, b) => (a.price > b.price) ? 1 : -1)
            
            this.kits = data

            // order the kits by price (from the cheapest to the most expensive)
            
        }
    },
    beforeMount() {
        this.retriveData()
    },
    components: {
        DrumKitShort
    }
}
</script>

<style scoped>
.kits {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin-top: 5rem;
}

h1 {
    color: var(--primary-color);
    text-align: center;
    margin-top: 5rem;
}
div.line {
    width: 30%;
    height: 0.2rem;
    background-color: var(--primary-color);
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
}

@media screen and (max-width: 768px) {
    .kits {
        flex-direction: column;
    }
}

</style>