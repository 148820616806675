<template>
    <MainPopup @close="show = undefined" v-if="show">
        <MainLogin @logged="setdata" @register="show = 'register'" v-if="show == 'login'"/>
        <MainRegister @login="show = 'login'"  v-if="show == 'register'"/>
    </MainPopup>
    <div class="header">
        <a href="/"><img src="../../assets/logo.png" alt="sluzyyy logo" class="logo"></a> 
        <ul @click="showMobile" class="menu">
            <li><router-link to="/discography">discography <div class="bar"></div></router-link></li>
            <li><router-link @click="slideTo('drumkit')" to="/#drumkit">Drum Kits <div class="bar"></div></router-link></li>
            <li><router-link to="/about">About <div class="bar"></div></router-link></li>
            <li><router-link to="/contact">Contact <div class="bar"></div></router-link></li>
        </ul>
        <div class="userWrapper">
            <img src="../../assets/login.png" alt="login icon" class="login" >
            <div v-if="user" class="userinfos">
                <!-- mail and credîts -->
                <p>{{user.email}}</p>
                <p>{{user.credits}} credits</p>
            </div>
        </div>
    </div>
</template>

<script>

import MainPopup from '../views/MainPopup.vue';
import MainLogin from '../parts/MainLogin.vue';
import MainRegister from '../parts/MainRegister.vue';
import axios from 'axios';

export default {
    name: 'MainMenu',
    data() {
        return {
            show: undefined,
            user: undefined
        }
    },
    methods: {
        showMobile() {
            if (window.innerWidth > 768) return;
            document.querySelector('ul.menu').classList.toggle('show');
        },
        slideTo(id) {
            var element = document.querySelector('#'+id);
            if (!element) return; 

            element.scrollIntoView({behavior: "smooth"});
        },
        setdata(data) {
            this.user = data;
            this.show = undefined;
        }
    },
    mounted() {
        // if token is not set, do nothing
        if (!localStorage.getItem('token')) return;

        axios.defaults.headers.common['authorization'] = localStorage.getItem('token');

        // get user info
        axios.get('http://localhost:3000/user').then((res) => {
            this.user = res.data.user;
        }).catch((err) => {
            console.log(err);
        });
    },
    components: {
        MainPopup,
        MainLogin,
        MainRegister
    }
}
</script>

<style scoped>
div.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--padding);

    color: white;
    background-color: transparent;
    z-index: 100;
}

img.logo {
    height: var(--icon-size);
}

img.login {
    height: var(--icon-size);
}

ul.menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--padding);

    list-style-type: none;

    margin: 0;
    padding: 0;
}

ul.menu li {
    padding: var(--padding);
}

ul.menu li a {
    color: var(--secondary-color);
    text-decoration: none;
    position: relative;
    padding: calc(var(--padding) / 2);
    font-size: 1.3em;
    font-weight: bold;
}

ul.menu li a div.bar {
    position: absolute;
    bottom: 0;
    left: calc(var(--padding) / 4);
    width: 0%;
    height: var(--menu-bar-height);
    background-color: var(--accent-color);
    transition: width 0.3s ease-in-out;
}

ul.menu li a:hover div.bar {
    width: 100%;
}

/*phone */
@media screen and (max-width: 768px) {

    img.logo {
        width: calc(var(--icon-size) * 1.5);
    }

    ul.menu::before {
        content: "☰";
        position: absolute;
        top: calc( 100% + var(--menu-min-height) / 2);
        left: 0;
        height: var(--menu-min-height);
        width: 100%;

        color: var(--secondary-color);
        font-size: 1.5em;
        font-weight: bold;
        text-align: center;
        z-index: 100;
    }

    ul.menu.show::before {
        top : calc(0px - var(--menu-min-height));
        color: var(--secondary-color);
        content: "✖";
        z-index: 1000;
    }

    ul.menu {
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        position: absolute;
        top: 0;
        left: 0;
        height: fit-content;
        width: 60%;
        margin: 0 20%;
        padding-bottom: var(--padding);

        border-radius: 10px;

        background-color: var(--primary-color);
        z-index: 100;

        transition: transform 0.3s ease-in-out;
        transform: translateY(-100%);
    }

    ul.menu.show {
        transform: translateY(calc(var(--menu-min-height) + var(--padding)));
    }

    /*triangle tip in the direction of the menu */
    ul.menu::after {
        content: "";
        position: absolute;
        top: calc(0px - var(--menu-bar-height));
        left: calc(50% - var(--menu-bar-height));
        height: 0;
        width: 0;
        border-left: var(--menu-bar-height) solid transparent;
        border-right: var(--menu-bar-height) solid transparent;
        border-bottom: var(--menu-bar-height) solid var(--primary-color);
    }

    ul.menu li {
        padding: calc(var(--padding) / 2);
    }

    ul.menu li a {
        font-size: 1.5em;
    }
}

</style>