<template>
    <div class="footerwrapper">
        <mainSocials/>
        <p>© 2023 Sluzyyy all rights reserved</p>
    </div>
</template>

<script>
import mainSocials from '../elements/mainSocials.vue';

export default {
    name: 'MainFooter',
    components: {
        mainSocials
    }
}
</script>

<style scoped>

div.footerwrapper {
    position: relative;
    height: var(--footer-height);
    width: calc(100% - var(--padding)*6);
    background-color: var(--secondary-color);
    color: var(--primary-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: calc(var(--padding)*3);

    min-height: 20vh;
}

p {
    text-align: center;
    font-size: 1.1rem;
    margin: 0;
    padding: 0;
}
</style>