<template>
  <router-view/>
</template>

<script>
export default {
  mounted() {
    // Dynamically set the title and favicon using JavaScript
    this.setHeadInfo();
  },
  methods: {
    setHeadInfo() {
      const favicon = document.querySelector("link[rel='icon']");
      const title = document.querySelector("title");
      
      if (favicon) {
        favicon.href = require("./assets/logo.png");
      }
      
      if (title) {
        title.innerText = "sluzyyy";
      }
    },
  },
};
</script>



<style>

:root {
    --primary-color: #000;
    --secondary-color: #fff;
    --accent-color: #30c174;
    --red-color: #af1e1e;
    
    --padding : 1em;

    

    --icon-size : 3em;

    --menu-min-height: 60px;
    --menu-bar-height: 2px;

    --video-height: 100vh;
    --video-width: 100%;

    --video-overlay-color: rgba(0, 0, 0, 0.5);
    --video-overlay-height: 100%;
    --video-overlay-width: 100%;


}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
}
</style>
